import React from "react";

export class TrixEditor extends React.Component {
    constructor(props) {
        super(props);

        this.id = this.generateId();
        this.container = null;
        this.editor = null;

        this.state = {
            showMergeTags: false,
            tags: []
        };

        document.addEventListener("trix-before-initialize", () => {
            console.log("trix-before-initialize");
            Trix.config.blockAttributes.default.tagName = "p";
            Trix.config.blockAttributes.default.breakOnReturn = true;

            Trix.Block.prototype.breaksOnReturn = function () {
                const attr = this.getLastAttribute();
                // Function `Trix.getBlockConfig()` was not found so I replaced it with analog:
                const config = Trix.config.blockAttributes[attr ? attr : "default"];
                return config ? config.breakOnReturn : false;
            };

            Trix.LineBreakInsertion.prototype.shouldInsertBlockBreak = function () {
                if (this.block.hasAttributes() && this.block.isListItem() && !this.block.isEmpty()) {
                    return this.startLocation.offset > 0;
                } else {
                    return !this.shouldBreakFormattedBlock() ? this.breaksOnReturn : false;
                }
            };
        });
    }

    generateId() {
        let dt = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return "T" + uuid;
    }

    componentDidMount() {
        const props = this.props;
        console.log("componentDidMount");




        this.container = document.getElementById(`editor-${this.id}`);
        if (this.container) {



            this.container.addEventListener("trix-initialize", () => {
                this.editor = this.container.editor;
                if (!this.editor) {
                    console.error("cannot find trix editor");
                }

                if (props.onEditorReady && typeof props.onEditorReady === "function") {
                    props.onEditorReady(this.editor);
                }
            }, false);
            this.container.addEventListener('trix-change', this.handleChange.bind(this), false);

            if (props.uploadURL) {
                this.container.addEventListener("trix-attachment-add", this.handleUpload.bind(this));
            }
        } else {
            console.error("editor not found");
        }
    }

    componentWillUnmount() {
        this.container.removeEventListener("trix-initialize", this.handleChange);
        this.container.removeEventListener("trix-change", this.handleChange);

        if (this.props.uploadURL) {
            this.container.removeEventListener("trix-attachment-add", this.handleUpload);
        }
    }

    handleChange(e) {
        const props = this.props;
        const text = e.target.innerText;

        if (props.onChange) {
            props.onChange(e.target.innerHTML, text);
        }

        const range = this.editor?.getSelectedRange();

        // Check for collapse selection
        if (text && range[0] === range[1]) {
            // Check for merge tag trigger
            if (props.mergeTags) {
                const lastChar = range[0] - 1;
                if (lastChar >= 0 && lastChar < text.length) {
                    const trigger = text[lastChar];
                    for (let i = 0; i < props.mergeTags.length; i++) {
                        if (trigger === props.mergeTags[i].trigger) {
                            this.setState({
                                showMergeTags: true,
                                tags: props.mergeTags[i].tags
                            });
                            break;
                        }
                    }
                }
            }
        }
    }

    handleUpload(e) {
        const attachment = e.attachment;
        if (attachment.file) {
            return this.uploadAttachment(attachment);
        }
    }

    uploadAttachment(attachment) {
        const file = attachment.file;
        const form = new FormData();
        // Add any custom data that were passed
        if (this.props.uploadData) {
            for (let k in this.props.uploadData) {
                form.append(k, this.props.uploadData[k]);
            }
        }

        form.append((this.props.fileParamName || "file"), file);
        const xhr = new XMLHttpRequest();
        xhr.open("POST", this.props.uploadURL, true);
        xhr.upload.onprogress = (event) => {
            const progress = (event.loaded / event.total) * 100;
            return attachment.setUploadProgress(progress);
        };
        xhr.onload = () => {
            let url = xhr.responseText;
            if (xhr.status >= 200 && xhr.status < 300) {
                return attachment.setAttributes({
                    url: url,
                    href: url
                });
            }
        };
        return xhr.send(form);
    }

    handleTagSelected(t, e) {
        e.preventDefault();

        this.setState({
            showMergeTags: false
        });

        this.editor?.expandSelectionInDirection("backward");
        this.editor?.insertString(t.tag);
    }

    renderTagSelector(tags) {
        if (!tags || !this.editor) {
            return null;
        }

        const editorPosition = document.getElementById("trix-editor-top-level-" + this.id)?.getBoundingClientRect();
        const rect = this.editor?.getClientRectAtPosition(this.editor?.getSelectedRange()[0]);
        const boxStyle = {
            position: "absolute",
            top: rect.top + 25 - editorPosition.top,
            left: rect.left + 25 - editorPosition.left,
            width: "250px",
            boxSizing: "border-box",
            padding: 0,
            margin: ".2em 0 0",
            backgroundColor: "hsla(0,0%,100%,.9)",
            borderRadius: ".3em",
            background: "linear-gradient(to bottom right, white, hsla(0,0%,100%,.8))",
            border: "1px solid rgba(0,0,0,.3)",
            boxShadow: ".05em .2em .6em rgba(0,0,0,.2)",
            textShadow: "none"
        };
        const tagStyle = {
            display: "block",
            padding: ".2em .5em",
            cursor: "pointer"
        };
        return (
            <div style={boxStyle} className="react-trix-suggestions">
                {tags.map((t) => (
                    <a key={t.name} style={tagStyle} href="#" onClick={this.handleTagSelected.bind(this, t)}>{t.name}</a>
                ))}
            </div>
        );
    }

    render() {
        const { showMergeTags, tags } = this.state;
        const { className, autoFocus, placeholder, toolbar, value } = this.props;

        const attributes = {
            id: `editor-${this.id}`,
            input: `input-${this.id}`,
            className: className,
            autofocus: autoFocus ? "autofocus" : undefined,
            placeholder: placeholder,
            toolbar: toolbar
        };

        let mergeTags = null;
        if (showMergeTags) {
            mergeTags = this.renderTagSelector(tags);
        }

        return (
            <div id={"trix-editor-top-level-" + this.id} style={{ position: "relative" }}>
                {React.createElement("trix-editor", attributes)}
                <input
                    type="hidden"
                    id={`input-${this.id}`}
                    value={value}
                />
                {mergeTags}
            </div>
        );
    }
}

export default TrixEditor;
