import React, { useEffect, useRef, useState } from "react";
import FileItemForField from "./file-item-for-field";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

export default function FilesFiled({ isNew = false, isSingle = false, accept = undefined, size = null, value = [], onChange, editMode, onRemoved }) {
    const { t } = useTranslation();
    const [files, setFiles] = useState(value);
    const [uploading, setUploading] = useState(false);
    const uploadingRef = useRef(false);
    const intervalRef = useRef(null);
    const filesRef = useRef(files);

    useEffect(() => {
        filesRef.current = files;
    }, [files]);

    useEffect(() => {
        setFiles(value);
    }, [value]);
    const addFiles = f => setFiles(oldFiles => [...oldFiles, ...f]);
    const handleDrop = f => {
        setUploading(false);
        uploadingRef.current = false;

        addFiles(f.map(i => {
            const fileId = uuidv4();
            return {
                id: fileId,
                file: i,
                name: i.path,
                status: "uploading"
            };
        }));

        intervalRef.current = setInterval(function () {
            const anyLeft = filesRef.current.some(file => file.status == "uploading");
            const anyUploaded = filesRef.current.some(file => file.status == "uploaded");

            if (!anyLeft && !uploadingRef.current && anyUploaded) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;

                uploadingRef.current = true;

                const newData = filesRef.current.filter(file => file.status == "uploaded")
                    .map(file => ({
                        name: file.name,
                        path: file?.data?.[0]?.path,
                    }));

                // console.log("onChange", newData);
                onChange(newData);
            }
        }, 1000);
        // onDrop(files);
    };
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop: handleDrop,
        multiple: !isSingle,
        accept,
    });
    const onFileUploadError = f => setFiles(oldFiles => oldFiles.filter(i => {
        return i.id !== f.id;
    }));
    const onFileUploadSuccess = (f, data) => {
        // console.log("onFileUploadSuccess", f.id, data);
        // const newFiles = files.map(i => {
        //     return i.id === f.id ? { ...f, status: "uploaded", data } : i;
        // });
        setFiles(prevFiles => {
            return prevFiles.map(i => {
                return i.id === f.id ? { ...f, status: "uploaded", data } : i;
            });
        });


        // const anyLeft = newFiles.some(file => file.status == "uploading");
        // console.log("anyLeft????", anyLeft, files);


        // if (!anyLeft && !uploadingRef.current) {
        //     setUploading(true);

        //     uploadingRef.current = true;

        //     const newData = newFiles.filter(file => file.status == "uploaded")
        //         .map(file => ({
        //             name: file.name,
        //             path: file?.data?.[0]?.path,
        //         }));

        //     console.log("onChange", newData);
        //     onChange(newData);
        // }

    };

    return <>
        <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <span>{t("attachments_drop_here")}</span> :
                    <span>{t("attachments_drop_n_drag")}</span>
            }
        </Container>
        {files.length > 0 && <FilesList>
            {files.map(f => <FileItemForField key={f.id}
                file={f}
                isNew={isNew}
                isSingle={isSingle}
                size={size}
                editMode={editMode}
                onRemoved={onRemoved}
                onSuccess={(data) => onFileUploadSuccess(f, data)}
                onError={() => onFileUploadError(f)} />)}
        </FilesList>}
    </>;
}

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  `;

const FilesList = styled.div`
    margin-top: 30px;
`;

const getColor = (props) => {
    if (props.isDragAccept) {
        return "#36B37E";
    }
    if (props.isDragReject) {
        return "#DE350B";
    }
    if (props.isDragActive) {
        return "#42526E";
    }
    return "#eeeeee";
};