
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


export const Wysywig = ({ content, onChange, onBlur }) => {
    const quillRef = React.useRef(null);

    React.useEffect(() => {
        if (quillRef.current) {
            quillRef.current.getEditor().on("blur", () => {
                if (onBlur) onBlur();
            });
        }
    }, [onBlur]);
    const handleContentSave = (content) => {
        return content?.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    };

    const handleContentLoad = (savedContent) => {
        const editableContent = savedContent?.replace(/(&nbsp;){4}/g, '\t');
        return editableContent;
    };

    return (
        <ReactQuill
            ref={quillRef}
            preserveWhitespace
            value={content}
            onChange={(html) => onChange(html)}
            onBlur={onBlur}
            modules={{
                toolbar: [
                    [{ header: "1" }, { header: "2" }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["bold", "italic", "underline"],
                    // ["link", "image"],
                    ["blockquote", "code-block"],
                    [{ align: [] }],
                    ["clean"],
                ],
            }}
        />
    );
};