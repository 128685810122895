import React from "react";
import styled from "styled-components";
import useMediaQuery from "../../hooks/use-media-query";
import { useResizeDetector } from "react-resize-detector";
import { withResizeDetector } from "react-resize-detector";
import Widget from "./widget";

function Widgets({ module, widgets, width, height, documentId }) {
    const isSmall = width < 500;
    const isMedium = width >= 500 && width < 900;
    const isLarge = width >= 900;

    return <Wrapper>
        {widgets.map(widget => {
            return <WidgetWrapper
                key={widget.name}
                isSmall={isSmall}
                isMedium={isMedium}
                isLarge={isLarge}
                height={widget.height}
                min={widget.min}
                max={widget.max ?? "auto"}
                medium={widget.medium}
                small={widget.small}
                large={widget.large}>
                <ErrorBoundary>
                    <Widget name={widget.name} color={widget.color} module={module} documentId={documentId} />
                </ErrorBoundary>
            </WidgetWrapper>;
        })}
    </Wrapper>;
}

export default withResizeDetector(Widgets);

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
`;

const WidgetWrapper = styled.div`
    padding: 10px;
    box-sizing: border-box;
    min-width: ${props => props.min};
    max-width: ${props => props.max};
    width: ${props => {
        if (props.isSmall) {
            return props.small;
        } else if (props.isMedium) {
            return props.medium;
        } else {
            return props.large;
        }
    }};
`;

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) { return { hasError: true, error }; }
    render() {
        if (this.state.hasError) {
            console.log(this.state);
            return <Widget name="ERROR" color="red" />;
        }
        return this.props.children;
    }
}