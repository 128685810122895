import React, { useEffect, useRef, useState, useCallback } from "react";
import { AsyncSelect } from "@atlaskit/select";
import apiClient from "../../api/api-client";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CreateDocumentModal from "../documents/create-document-modal";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import Select from "@atlaskit/select";
import useDocument from "../../hooks/use-document";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import useDocumentsList from "../../hooks/use-documents-list";
import useAsync from "../../hooks/use-async";
import { NewPageInner } from "../../pages/new";



function SearchDocumentsByType({ typeId, onChange, onBlur, onSaved, value, isDisabled, isModal, filter = null, link = null, selectedDocumentId, selectedDocumentTypeId, document, autoFocus = true, direction = -1, isClearable = true, isRequired = false, filterByRootDocument = false, root = null, optionsData = {}, isInternal = false, fieldName = "", isMulti = false, rawValue = [], currentRoot = null, contextDocument, isNested = false, onNestedSubmit }) {
    const [open, setOpen] = useState(false);
    const [allOptions, setAllOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [filters, setFilters] = useState(null);
    const selectRef = useRef();
    const [query, setQuery] = useState("");
    const [selected, setSelected] = useState(null);
    const { isSuccess, document: doc, fields, isPending } = useDocument(selectedDocumentId, selectedDocumentId && selectedDocumentTypeId);
    const { t } = useTranslation();
    const [internalValue, setInternalValue] = useState(null);
    const [salt, setSalt] = useState(Math.random());
    const { data: documentTypeData, run: documentTypeRun } = useAsync();
    const [addNew, setAddNew] = useState(false);

    useEffect(() => {
        documentTypeRun(apiClient(`document-types/${typeId}/show`));
    }, [typeId]);

    useEffect(() => {
        if (!isSuccess) return;
        if (!selectedDocumentId) {
            setSelected(null);
            return;
        }

        setSelected({
            value: doc?.id,
            label: findLabel(doc, fields),
            data: doc,
        });

        if (isInternal) {
            setInternalValue({
                value: doc?.id,
                label: findLabel(doc, fields),
                data: doc,
            });
        }
    }, [typeId, selectedDocumentId, isSuccess, doc?.id]);

    function handleOnChange(e) {
        if (isInternal) {
            setInternalValue(e);
        }

        if (onChange) {
            return onChange(e);
        }
    }

    // const filterMethod = React.useCallback(item => {
    //     if (!filter || !link) return true;
    //     const itemLinks = item?.data?.links?.filter(il => il?.document_type?.id === link?.filtered_by_id)?.map(il => il?.id) || [];
    //     return itemLinks?.includes(filter?.value);
    // }, [ filter, link, filter?.value ]);

    // const filterByDocumentMethod = React.useCallback(item => {
    //     if (!link?.filterByDocument) return true;
    //     const linkedDocumentIds = document?.links?.map(i => i?.linked_document_id);
    //     return linkedDocumentIds?.includes(item?.value);
    // }, [ link?.filterByDocument, document?.id ]);

    useEffect(() => {
        // console.log(selectRef?.current);
        // todo, clear async select
    }, [typeId]);

    useEffect(() => {
        // if (!query) return;
        debouncedChangeHandler(query);
    }, [filter?.value, query, typeId, salt]);

    // useEffect(() => {
    //     setOptions(allOptions.filter(i => {
    //         if (filterMethod && filterByDocumentMethod) {
    //             return filterByDocumentMethod(i) && filterMethod(i);
    //         }
    //         if (filterMethod) return filterMethod(i);
    //         if (filterByDocumentMethod) return filterByDocumentMethod(i);
    //         return true;
    //     }));
    // }, [ filter, link ]);

    useEffect(() => {
        search(query);
    }, [typeId]);

    const search = q => {
        setIsLoading(true);
        let params = {};
        let url = `workflow/document-type/${typeId}/documents?query=${q}&per_page=50&dir=${direction}`;

        if (filter && link && filter?.value) {
            params = {
                condition: "AND",
                links: [
                    { operator: "=", document_id: filter?.value }
                ],
            };
        }

        if (link?.filterByDocument) {
            params = {
                condition: "AND",
                links: [
                    { operator: "=", document_id: document?.id }
                ],
            };
        }

        if (link?.additionalSearchField) {
            url = `workflow/document-type/${typeId}/documents?per_page=50&dir=${direction}`;
            params = {
                condition: "OR",
                filters: [
                    {
                        name: "name",
                        operator: "contains",
                        value: q,
                    },
                    {
                        name: link.additionalSearchField,
                        operator: "contains",
                        value: q,
                    },
                ]
            };
        }

        if (link?.filterByField) {
            params = {
                ...params,
                where: [
                    {
                        name: link?.filterByField?.name,
                        operator: link?.filterByField?.operator,
                        value: link?.filterByField?.value,
                    },
                ]
            };
        }

        if (optionsData?.filterByField) {
            params = {
                ...params,
                where: [
                    ...params?.where ?? [],
                    {
                        name: optionsData?.filterByField?.name,
                        operator: optionsData?.filterByField?.operator,
                        value: optionsData?.filterByField?.value,
                    },
                ]
            };
        }

        if (optionsData?.filter) {
            const dt = optionsData?.filter?.documentTypeId;
            const field = optionsData?.filter?.field;
            const saidValue = (root ?? currentRoot)?.rawValues?.find(i => i?.document_type_field_id == field)?.value;

            if (!saidValue) {
                setAllOptions([]);
                setOptions([]);
                setIsLoading(false);
                return;
            }

            params = {
                ...params,
                linksWhere: { field: optionsData?.filter?.field, value: saidValue, documentTypeId: optionsData?.filter?.documentTypeId },
            };
        }

        // 
        if (optionsData?.filterByDT) {
            const dt = optionsData?.filterByDT?.documentTypeId;
            const field = optionsData?.filterByDT?.field;
            const saidValue = (root ?? currentRoot)?.rawValues?.find(i => i?.document_type_field_id == field)?.value;
            const saidValues = (root ?? currentRoot)?.rawValues?.filter(i => i?.document_type_field_id == field);


            if (!saidValue) {
                setAllOptions([]);
                setOptions([]);
                setIsLoading(false);
                return;
            }

            params = {
                ...params,
                linksCondition: "or",
                links: [{
                    operator: "=", document_id: saidValue,
                }],
            };
        }

        if (root?.id && filterByRootDocument) {
            params = {
                ...params,
                condition: "AND",
                links: [
                    { operator: "=", document_id: root?.id }
                ],
            };
        }

        if (currentRoot?.id && filterByRootDocument) {
            params = {
                ...params,
                condition: "AND",
                links: [
                    { operator: "=", document_id: currentRoot?.id }
                ],
            };
        }

        if (contextDocument?.id && optionsData?.filterByParentDocument) {
            params = {
                ...params,
                condition: "AND",
                links: [
                    { operator: "=", document_id: contextDocument?.id }
                ],
            };
        }

        apiClient(url, { method: "POST", data: params })
            .then(response => {
                const opt = response?.data?.map(i => {
                    const label = i.values?.find(a => a.name == "name")?.value;
                    const additionalField = link?.additionalSearchField ? i.values?.find(a => a.name == link?.additionalSearchField)?.value : null;

                    return ({
                        label: additionalField ? `${additionalField} - ${label}` : label,
                        value: i.id,
                        data: i,
                    });
                });

                setAllOptions([...opt]);
                setOptions([...opt]);

                // setOptions(opt.filter(i => {
                //     if (filterMethod && filterByDocumentMethod) {
                //         return filterByDocumentMethod(i) && filterMethod(i);
                //     }
                //     if (filterMethod) return filterMethod(i);
                //     if (filterByDocumentMethod) return filterByDocumentMethod(i);
                //     return true;
                // }));

                return Promise.resolve(opt);
            })
            .catch(() => {
                setOptions([]);
                Promise.resolve([]);
            })
            .finally(() => setIsLoading(false));
    };


    const debouncedChangeHandler = useCallback(debounce(search, 500), [filter?.value, typeId, currentRoot]);
    const addNewEnabled = optionsData?.addNewEnabled ?? false;

    return <Wrapper>
        <SelectWrapper>
            <Select autoFocus={autoFocus}
                // loadOptions={search} 
                value={!isMulti ? selected : documents?.data?.map(doc => ({
                    value: doc?.id,
                    label: doc?.values?.name,
                    data: doc,
                }))}
                options={options}
                onChange={handleOnChange}
                onBlur={onBlur}
                inputValue={query}
                onInputChange={setQuery}
                // onBlur={() => setSalt(Math.random())}
                onFocus={() => setSalt(Math.random())}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isRequired={isRequired}
                isClearable={isClearable}
                filterOption={() => true}
                isMulti={isMulti}
                // defaultOptions={options} 
                ref={selectRef} />
        </SelectWrapper>
        {
            documentTypeData?.creatable && addNewEnabled && <Row>
                <AddNewWrapper>
                    <Button appearance="primary" onClick={() => {
                        setAddNew(true);
                    }}>
                        +
                    </Button>
                </AddNewWrapper>
            </Row>
        }
        {/* {isModal && <Row>
            <AddNewWrapper>
                <Link to="" onClick={e => {
                    e.preventDefault();
                    setOpen(true);
                }}>
                    <Button appearance="default">
                        {t("search_documents_by_type_add")}
                    </Button>
                </Link>
            </AddNewWrapper>
            <Spacer />
        </Row>}
        {open && <CreateDocumentModal documentTypeId={typeId} open={open} onClose={() => setOpen(false)} onSubmit={e => {
            setOpen(false);
            onChange({
                label: e.name,
                value: e.id
            });
        }} />} */}
        {addNew && <AddNewDocumentDrawer
            isNested={true}
            documentTypeId={typeId}
            space={documentTypeData?.space}
            linkWithDocumentFromField={optionsData?.linkWithDocumentFromField}
            root={root}
            onNestedSubmit={onNestedSubmit}
            onClose={
                (res) => {
                    setAddNew(false);

                    if (res) {
                        handleOnChange(res);

                        if (onSaved) {
                            onSaved(res?.value)
                                .then(() => handleOnChange(res));
                        }
                    }
                }
            }
        />}
    </Wrapper>;
}

function findLabel(document, fields) {
    const field = fields?.find(i => i?.name === "name");

    if (!field) return "";

    return document?.values?.[field?.id] ?? "";
}


export default SearchDocumentsByType;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const SelectWrapper = styled.div`
    width: 100%;
`;

const Row = styled.div`
    display: flex;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const AddNewWrapper = styled.div`
    padding: 10px 5px;
    font-weight: bold;
    padding-left: 20px;
`;

export function AddNewDocumentDrawer({ documentTypeId, space, onClose, root, linkWithDocumentFromField = null, isNested = false, onNestedSubmit }) {
    const { run, isPending: isCreating, error, isError: isCreatingError } = useAsync();
    const { run: runGet } = useAsync();

    let linksData = undefined;

    if (linkWithDocumentFromField && root?.values?.[linkWithDocumentFromField]) {
        linksData = [
            { id: root?.values?.[linkWithDocumentFromField], type: 3 },
        ];
    }

    const handleSubmit = data => {
        run(apiClient(`workflow/document-type/${documentTypeId}/document`, {
            data: {
                ...data,
                links: linksData,
            },
        }))
            .then(res => {
                runGet(apiClient(`workflow/document/${res.id}?legacy_links=false`))
                    .then(data => {
                        const nameField = data?.document_type?.fields?.find(i => i?.name == "name");
                        const label = data?.values?.find(i => i?.document_type_field_id === nameField?.id)?.value;

                        if (label) {
                            onClose({
                                label,
                                value: data?.id,
                            });
                        }
                    })
                    .catch(() => { onClose(); });
            })
            .catch(() => {
                toast.error(t("new_error"));
            });
    };

    return <div className="newDocumentDrawerWrapper" tabIndex="-1">
        <div className="newDocumentDrawerClose" onClick={() => onClose()}>
            &times;
        </div>

        <div className="">
            <NewPageInner
                onNestedSubmit={onNestedSubmit}
                isNested={isNested}
                space={space}
                onSuccess={() => { }}
                handleSubmit={handleSubmit}
                isCreating={isCreating}
                error={error}
                isCreatingError={isCreatingError}
            />
        </div>

        <style dangerouslySetInnerHTML={{
            __html: `
            .newDocumentDrawerWrapper {
                background-color: rgb(255, 255, 255);
                border-bottom-color: rgb(229, 231, 235);
                border-bottom-style: solid;
                border-bottom-width: 0px;
                border-left-color: rgb(229, 231, 235);
                border-left-style: solid;
                border-left-width: 0px;
                border-right-color: rgb(229, 231, 235);
                border-right-style: solid;
                border-right-width: 0px;
                border-top-color: rgb(229, 231, 235);
                border-top-style: solid;
                border-top-width: 0px;
                box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px;
                box-sizing: border-box;
                color: rgb(0, 0, 0);
                display: block;
                font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                font-feature-settings: normal;
                font-variation-settings: normal;
                height: 100vh;
                line-height: 24px;
                max-width: 800px;
                outline-color: rgb(0, 0, 0);
                outline-style: none;
                outline-width: 0px;
                overflow-y: auto;
                padding-bottom: 24px;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 24px;
                position: fixed;
                right: 0px;
                tab-size: 4;
                top: 0px;
                width: 90%;
                z-index: 400;
            }

            .newDocumentDrawerClose {
                border-bottom-color: rgb(229, 231, 235);
                border-bottom-style: solid;
                border-bottom-width: 0px;
                border-left-color: rgb(229, 231, 235);
                border-left-style: solid;
                border-left-width: 0px;
                border-right-color: rgb(229, 231, 235);
                border-right-style: solid;
                border-right-width: 0px;
                border-top-color: rgb(229, 231, 235);
                border-top-style: solid;
                border-top-width: 0px;
                box-sizing: border-box;
                color: rgb(0, 0, 0);
                cursor: pointer;
                display: block;
                font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                font-feature-settings: normal;
                font-variation-settings: normal;
                font-size: 24px;
                height: 56px;
                left: 0px;
                line-height: 24px;
                outline-color: rgb(0, 0, 0);
                outline-style: none;
                outline-width: 0px;
                padding-bottom: 16px;
                padding-left: 16px;
                padding-right: 16px;
                padding-top: 21px;
                position: absolute;
                tab-size: 4;
                top: 0px;
                width: 41.765625px;
            }
            `
        }}>

        </style>
    </div>;
}