import React, { useCallback, useState } from "react";
import Button from "@atlaskit/button/custom-theme-button";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import VidPlayIcon from "@atlaskit/icon/glyph/vid-play";
import TextArea from "@atlaskit/textarea";
import { Checkbox } from "@atlaskit/checkbox";
import SearchUsers from "../users/search-users";
import SelectDocumentWorkflow from "./select-document-workflow";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function RunUserWorkflow({ documentId, documentTypeId, onSuccess, initialWorkflow = null, onClosed }) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(initialWorkflow != null);
    const [message, setMessage] = useState("");
    const [isPrivate, setIsPrivate] = useState(true);
    const [user, setUser] = useState(null);
    const [workflow, setWorkflow] = useState(initialWorkflow);
    const { isPending, run } = useAsync();

    const close = () => {
        setIsOpen(false);
        setMessage("");
        setIsPrivate(true);
        onClosed && onClosed();
    };
    const open = () => setIsOpen(true);

    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        run(apiClient(`workflow/${workflow?.value}/document/${documentId}/user/${user?.id}`, {
            data: {
                message: message,
                is_private_message: isPrivate,
            }
        }))
            .then(() => {
                toast.success(t("run_workflow_success"));
                onSuccess && onSuccess();
                close();
            })
            .catch(error => {
                toast.error(t("run_workflow_error") + error?.message);
            });
    };

    return (
        <>
            {!initialWorkflow && <Button iconBefore={<VidPlayIcon />} onClick={open} appearance="primary"></Button>}

            <ModalTransition>
                {isOpen && (
                    <Modal
                        actions={[
                            { text: t("run_workflow_submit"), type: "submit", isDisabled: !workflow || !user, onClick: handleSubmit },
                            { text: t("run_workflow_cancel"), onClick: close, type: "button" },
                        ]}
                        onClose={close}
                        heading={t("run_workflow_heading")}
                        scrollBehavior="outside"
                    >
                        <Field id="name" name="name" label={t("run_workflow_workflow")} isRequired>
                            {() => (
                                <SelectDocumentWorkflow documentTypeId={documentTypeId} value={workflow} onChange={e => setWorkflow(e)} isDisabled={isPending} />
                            )}
                        </Field>
                        <Field id="user" name="user" label={t("run_workflow_recipient")} isRequired={true}>
                            {() => (
                                <SearchUsers onChange={setUser} isMulti={false} selectedUser={user} />
                            )}
                        </Field>
                        <Field id="message" name="message" label={t("run_workflow_message")}>
                            {() => (
                                <TextArea
                                    onChange={e => setMessage(e.target.value)}
                                    value={message}
                                    placeholder={t("run_workflow_message_placeholder")}
                                />
                            )}
                        </Field>
                        <Field id="private" name="private" label="">
                            {() => (
                                <Checkbox label={t("run_workflow_private")} isChecked={isPrivate} onChange={e => setIsPrivate(v => !v)} />
                            )}
                        </Field>
                    </Modal>
                )}
            </ModalTransition>
        </>
    );
}
