/* eslint-disable no-unreachable */
/* eslint-disable react/display-name */
import React, { useState, useRef, useEffect, useCallback } from "react";
import Scheduler from "./scheduler";
import "../../lib/scheduler/scheduler.stockholm.min.css";
import styled from "styled-components";
import { DateHelper, DomClassList } from "bryntum-scheduler";
import useCalendarEvents from "../../hooks/use-calendar-events";
import { Link } from "react-router-dom";
import CreateEventModal from "../documents/create-event-modal";
import EditEventModal from "../documents/edit-event-modal";
import NewCreateEventModal from "../documents/new-create-event-modal";
import useAsync from "../../hooks/use-async";
import { useAuth } from "../../providers/auth-provider";
import apiClient from "../../api/api-client";
import useDocumentLinkTypes from "../../hooks/use-document-link-types";
import { toast } from "react-toastify";
import { endOfWeek, format, startOfWeek } from "date-fns";
import RemoveDocumentModal from "../documents/remove-document-modal";
import Spinner from "../ui/spinner";
import Drawer from "../ui/drawer";
import TextField from "@atlaskit/textfield";
import { useTranslation } from "react-i18next";


export default function Calendar({ xId, yId, module, config, category, space, headerDocumentId, columns = [], colors = [], colorField = null, bgField = null, onlyEditUserId = false, userEdit = [], xFilters = null, yFilters = null }) {
    const { user } = useAuth();
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(() => startOfWeek(new Date()));
    const [endDate, setEndDate] = useState(() => {
        const end = new Date();
        end.setDate(end.getDate() + 30);
        return endOfWeek(end);
    });
    const schedulerRef = useRef();
    const loadLinks = config?.loadLinks ?? true;
    const { resources, events, isPending, reload: reloadEvents, eventsRelated } = useCalendarEvents(startDate, endDate, xId, yId, xFilters, yFilters, loadLinks);
    const [editorOpen, setEditorOpen] = useState(false);
    const [editorXId, setEditorXId] = useState(null);
    const [editorStartDate, setEditorStartDate] = useState(null);
    const [editorEndDate, setEditorEndDate] = useState(null);
    const [editorEventId, setEditorEventId] = useState(null);
    const { run, isPending: isSaving } = useAsync();
    const [documentToRemove, setDocumentToRemove] = useState(null);
    const [sort, setSort] = useState(() => columns.filter(i => i?.type != "rownumber").map(i => ({
        documentTypeId: i?.type == "document" ? xId : i?.documentLinkTypeId,
        text: i?.text,
    })));
    const target = React.useRef(null);
    const { height, width } = useWindowSize(target);
    const schedulerInstance = () => schedulerRef.current?.instance;

    const highlightChangeHandler = useCallback(value => {
        // const scheduler = schedulerInstance();
        const scheduler = schedulerRef.current.schedulerInstance;
        let firstMatchFound = false;


        scheduler.eventStore.forEach((task) => {
            const taskClassList = new DomClassList(task.cls);
            const matched = taskClassList.contains("b-match");
            console.log("CHECK", value, task.name.toLowerCase(), task.name.toLowerCase().indexOf(value) >= 0);

            if (task.name.toLowerCase().indexOf(value) >= 0) {
                if (!matched) {
                    taskClassList.add("b-match");
                }

                if (!firstMatchFound) {
                    scheduler.scrollEventIntoView(task);
                    firstMatchFound = true;
                }
            }
            else if (matched) {
                taskClassList.remove("b-match");
            }
            task.cls = taskClassList.value;
        });
        scheduler.element.classList[value.length > 0 ? "add" : "remove"]("b-highlighting");

    }, []);

    const resourcesList = resources?.map(r => {
        const name = r?.values?.find(i => i.name === "name")?.value;
        return { id: r?.id, name, links: r?.links || [], values: r?.values, };
    }) || [];

    const eventsList = events?.map(e => {
        const links = e?.links?.filter(i => i.document_type?.id === parseInt(xId));
        const headerLinks = e?.links?.filter(i => i.document_type?.id === headerDocumentId);
        const startDate = findValue(e, "start_date");
        const endDate = findValue(e, "end_date");
        const startTime = findValue(e, "start_time") ?? "00:00";
        const endTime = findValue(e, "end_time") ?? "23:59";
        const name = findValue(e, "name");

        const heading = headerLinks?.length > 0 ? findValue(headerLinks[0], "name") : undefined;

        if (config?.resourceField) {
            let adjustedStartDate = startDate;
            let adjustedEndDate = endDate;
            if (startDate == endDate && startDate?.length == 10) {
                adjustedStartDate = `${startDate} ${startTime}`;
                adjustedEndDate = `${endDate} ${endTime}`;
            }

            return resourcesList
                ?.filter(r => {
                    const resourceField = config?.resourceField;
                    const resourceId = findValue(e, resourceField);

                    return resourceId == r?.id;
                })
                ?.map(r => {
                    return {
                        resourceId: r?.id || undefined,
                        // startDate: transformDate(adjustedStartDate),
                        // endDate: transformDate(adjustedEndDate),
                        startDate: startDate?.length == 10 ? `${startDate} ${startTime}` : startDate,
                        endDate: endDate?.length == 10 ? `${endDate} ${endTime}` : endDate,
                        name: heading ? `${heading} (${name})` : name,
                        eventColor: findColor(e, colors, colorField),
                        eventId: e?.id,
                        heading,
                        allValues: valuesMap(e),
                    };
                });
        }

        return links?.map(l => {
            const resourceIndex = resourcesList.findIndex(i => i.id === l?.id);
            return {
                resourceId: l?.id || undefined,
                startDate,
                endDate,
                name: heading ? `${heading} (${name})` : name,
                eventColor: findColor(e, colors, colorField),
                eventId: e?.id,
                heading,
            };
        });
    })?.flat()?.filter(i => !!i) || [];

    const editEventDates = (eId, eStartDate, eEndDate, record) => {
        const start_date = format(eStartDate, "yyyy-MM-dd");
        const end_date = format(eEndDate, "yyyy-MM-dd");

        let data = {
            start_date,
            end_date,
        };


        if (record?.originalData?.resourceId != record?.data?.resourceId) {
            const newResourceId = record?.data?.resourceId;
            const resourceField = config?.resourceField;

            data = {
                ...data,
                [resourceField]: newResourceId,
            };
        }


        run(apiClient(`workflow/document/${eId}`, {
            method: "PATCH", data: data
        }))
            .then(() => toast.success("Daty zostały zmienione"))
            .finally(() => reloadEvents())
            .catch(error => {
                toast.error("Nie udało się zmienić dat: " + error?.message);

            });
    };

    if (!user?.id || (isPending && resources?.length == 0)) {
        return <Spinner />;
    }


    return <Wrapper ref={target}>
        <FlexWrapper>
            <Spacer />
            <div>
                <TextField onChange={e => highlightChangeHandler(e.target.value)} placeholder={t("Search")} />
            </div>
        </FlexWrapper>
        <Scheduler
            ref={schedulerRef}
            startDate={startDate}
            endDate={endDate}
            isLoading={isPending}
            height={height - 150}
            width={width - 350}
            columns={
                columns?.map(col => ({
                    type: col?.type === "rownumber" ? "rownumber" : null,
                    text: col?.text,
                    renderer: ({ record }) => recordRenderer(col, record, {
                        module: col?.module ?? module,
                        category: col?.category ?? category,
                        space: col?.space ?? space,
                        additionalColumn: col?.additionalColumn,
                        documentLinkTypeId: col?.documentLinkTypeId,
                    }),
                    sortable: (a, b) => {
                        if (col?.type == "document") {
                            return a?.data?.name?.localeCompare(b?.data?.name);
                        }

                        if (col?.type == "link") {
                            const aVal = a?.data?.links?.find(i => i?.document_type?.id === col?.documentLinkTypeId);
                            const aName = aVal?.values?.find(i => i.name === "name")?.value ?? "";

                            const bVal = b?.data?.links?.find(i => i?.document_type?.id === col?.documentLinkTypeId);
                            const bName = bVal?.values?.find(i => i.name === "name")?.value ?? "";

                            return aName?.localeCompare(bName);
                        }

                        return 0;
                    },
                    filterable: ({ record, value, operator }) => {
                        if (col?.type == "document") {
                            return record?.data?.name?.toLowerCase()?.includes(value?.toLowerCase());
                        }

                        if (col?.type == "link") {
                            const val = record?.data?.links?.find(i => i?.document_type?.id === col?.documentLinkTypeId);
                            const name = val?.values?.find(i => i.name === "name")?.value ?? "";

                            return name?.toLowerCase()?.includes(value?.toLowerCase());
                        }

                        return false;
                    }
                }))
            }
            resources={resourcesList}
            events={eventsList}
            emptyText="Brak rekordów."
            eventStyle="border"
            weekStartDay={1}
            zoomLevel={1}
            listeners={{
                beforeEventEdit({ eventRecord, resourceRecord }) {
                    if (onlyEditUserId && !ownsResource(resourceRecord?.id, resources, user?.id, userEdit)) {
                        return false;
                    }

                    const _xId = resourceRecord?.id;
                    const _startDate = eventRecord?.startDate;
                    const _endDate = eventRecord?.endDate;
                    setEditorOpen(true);
                    setEditorXId(_xId);
                    setEditorStartDate(_startDate);
                    setEditorEndDate(_endDate);
                    setEditorEventId(eventRecord?.data?.eventId);
                    return false;
                },
                beforeEventDrag({ eventRecord }) {
                    if (onlyEditUserId && !ownsResource(eventRecord?.data?.resourceId, resources, user?.id, userEdit)) {
                        return false;
                    }
                },
                afterEventDrop({ source, assignmentRecords, eventRecords, valid, context }) {
                    eventRecords?.forEach(record => {
                        editEventDates(record?.eventId, Date.parse(record?.startDate), Date.parse(convertToUTCDateOnly(record?.endDate)), record);

                        // const eventObj = eventsList?.find(i => i?.eventId == record?.eventId);
                        // if (!eventObj) {
                        //     editEventDates(record?.eventId, Date.parse(record?.startDate), Date.parse(record?.endDate));
                        // }
                        // const startDate = eventObj?.startDate;
                        // const endDate = eventObj?.endDate;

                        // const newStartDate = record?.startDate;
                        // const diff = compareDatesInDays(startDate, newStartDate);
                        // const newEndDate = incrementDateByDays(endDate, diff);
                        // editEventDates(record?.eventId, Date.parse(record?.startDate), newEndDate);
                    });
                },
                beforeEventResize({ eventRecord }) {
                    if (onlyEditUserId && !ownsResource(eventRecord?.data?.resourceId, resources, user?.id, userEdit)) {
                        return false;
                    }
                },
                eventResizeEnd({ wasChanged, eventRecord }) {
                    // if (!wasChanged) return;
                    editEventDates(eventRecord?.eventId, Date.parse(eventRecord?.startDate), Date.parse(convertToUTCDateOnly(eventRecord?.endDate)), eventRecord);
                },
                beforeEventDelete({ eventRecords, resourceRecord }) {
                    if (eventRecords?.length == 0) return false;
                    const eventRecord = eventRecords[0];


                    if (onlyEditUserId && !ownsResource(eventRecord?.data?.resourceId, resources, user?.id, userEdit)) {
                        return false;
                    }

                    setDocumentToRemove(eventRecord?.eventId);
                    return false;
                },
                timeAxisChange({ config: { startDate, endDate } }) {
                    setStartDate(startDate);
                    setEndDate(endDate);
                }
            }}
            stripeFeature={true}
            timeRangesFeature={{
                showCurrentTimeLine: true,
            }}
            nonWorkingTimeFeature={true}
            summaryFeature={{
                renderer: ({ events }) => events?.length || "",
            }}
            eventTooltipFeature={
                {
                    template: config?.tooltip ? (data) => generateTooltip(config, data, eventsRelated) : null,
                }
            }
            filterFeature={true}
            barMargin={7}
            viewPreset={{
                base: "weekAndDayLetter",
                headers: [{
                    unit: "week",
                    dateFormat: "WW: MM.YYYY",
                    verticalColumnWidth: 115
                }, {
                    unit: "day",
                    dateFormat: "DD",
                    verticalColumnWidth: 25
                }]
            }}
            allowOverlap={config?.allowOverlap ?? true}
            eventDragFeature={{
                disabled: config?.eventDragDisabled ?? false,
                constrainDragToResource: config?.constrainDragToResource ?? true,
                constrainDragToTimeSlot: config?.constrainDragToTimeSlot ?? true,
                constrainDragToTimeline: config?.constrainDragToTimeline ?? true
            }}
            eventResizeFeature={{
                disabled: config?.eventResizeDisabled ?? false,
            }}
            tbar={[
                {
                    type: "date",
                    value: "up.startDate",
                    step: "1d",
                    onChange({ value }) {
                        // Preserve time, only changing "day"
                        const scheduler = schedulerRef.current.schedulerInstance;
                        const diff = DateHelper.diff(DateHelper.clearTime(scheduler.startDate), value, "days");
                        scheduler.startDate = DateHelper.add(scheduler.startDate, diff, "days");
                    }
                },
                "->",
                {
                    type: "buttongroup",
                    items: [
                        {
                            type: "button",
                            icon: "b-fa-angle-left",
                            tooltip: "View previous day",
                            onAction() {
                                schedulerRef.current.schedulerInstance.shiftPrevious();
                            }
                        },
                        {
                            type: "button",
                            ref: "todayButton",
                            text: "Today",
                            tooltip: "View today, to see the current time line",
                            onAction() {
                                const today = DateHelper.clearTime(new Date());
                                today.setHours(5);
                                schedulerRef.current.schedulerInstance.setTimeSpan(today, DateHelper.add(today, 30, "day"));
                            }
                        },
                        {
                            type: "button",
                            icon: "b-fa-angle-right",
                            tooltip: "View next day",
                            onAction() {
                                schedulerRef.current.schedulerInstance.shiftNext();
                            }
                        }
                    ]
                },
            ]}
            tooltip={CustomTooltipRenderer}
        >
            <div></div>
        </Scheduler>

        {
            editorOpen && editorEventId && !config?.newModal && <EditEventModal
                documentTypeId={yId}
                documentLinkTypeId={xId}
                open={editorOpen}
                startDate={editorStartDate}
                endDate={editorEndDate}
                eventId={editorEventId}
                resourceId={editorXId}
                onClose={() => setEditorOpen(false)}
                onEdit={() => {
                    setEditorOpen(false);
                    reloadEvents();
                    toast.success("Dokument został zapisany");
                }} />
        }

        {/* {editorOpen && editorEventId && config?.newModal && <NewEditEventModal
            documentTypeId={yId}
            documentLinkTypeId={xId}
            open={editorOpen}
            startDate={editorStartDate}
            endDate={editorEndDate}
            eventId={editorEventId}
            resourceId={editorXId}
            onClose={() => setEditorOpen(false)}
            onEdit={() => {
                setEditorOpen(false);
                reloadEvents();
                toast.success("Dokument został zapisany");
            }} />} */}

        {
            editorOpen && editorEventId && config?.newModal && <Drawer id={editorEventId} space={config?.modalSpaceSlug} module={config?.modalModuleSlug} category={config?.modalCategorySlug} onReload={() => {
                reloadEvents();
            }} onClose={() => {
                reloadEvents();
                setEditorOpen(false);
                setEditorEventId(null);
            }} />
        }

        {
            editorOpen && !editorEventId && !config?.newModal && <CreateEventModal
                documentTypeId={yId}
                documentLinkTypeId={xId}
                open={editorOpen}
                startDate={editorStartDate}
                endDate={editorEndDate}
                eventId={editorEventId}
                resourceId={editorXId}
                onClose={() => setEditorOpen(false)}
                onEdit={() => {
                    setEditorOpen(false);
                    reloadEvents();
                    toast.success("Dokument został zapisany");
                }}
                onSubmit={e => {
                    setEditorOpen(false);
                    toast.info("Dokument został utworzony");
                    reloadEvents();
                }} />
        }

        {
            editorOpen && !editorEventId && config?.newModal && editorStartDate && editorEndDate && editorXId && <NewCreateEventModal
                slug={config?.modalSpaceSlug}
                documentTypeId={yId}
                documentLinkTypeId={xId}
                open={editorOpen}
                startDate={editorStartDate}
                endDate={editorEndDate}
                eventId={editorEventId}
                resourceId={editorXId}
                resourceField={config?.resourceField}
                onClose={() => {
                    setEditorOpen(false);
                    setEditorEndDate(null);
                    setEditorStartDate(null);
                    setEditorXId(null);
                }}
                onEdit={() => {
                    setEditorOpen(false);
                    setEditorEndDate(null);
                    setEditorStartDate(null);
                    setEditorXId(null);
                    reloadEvents();
                    toast.success("Dokument został zapisany");
                }}
                onSubmit={e => {
                    setEditorOpen(false);
                    setEditorEndDate(null);
                    setEditorStartDate(null);
                    setEditorXId(null);
                    toast.info("Dokument został utworzony");
                    reloadEvents();
                }} />
        }

        {
            documentToRemove && <RemoveDocumentModal document={{ id: documentToRemove }} onClose={() => setDocumentToRemove(false)} onRemoved={() => {
                reloadEvents();
                setDocumentToRemove(null);
            }} />
        }
    </Wrapper >;
}

function recordRenderer(col, record, { module, category, space, additionalColumn, documentLinkTypeId }) {
    if (col?.type === "document") {
        const additionalData = additionalColumn ? record?.values?.find(i => i.name === additionalColumn)?.value : null;

        return <div>
            <Link to={`/${module}/${category}/${space}/${record?.id}`}>{record?.name}</Link>
            {additionalData && <div>{additionalData}</div>}
        </div>;
    } else if (col?.type === "link") {
        const val = record?.links?.find(i => i?.document_type?.id === documentLinkTypeId);
        const name = val?.values?.find(i => i.name === "name")?.value;

        return <div>
            {val ? <Link to={`/${module}/${category}/${space}/${val.id}`}>{name}</Link> : "-"}
        </div>;
    } else if (col?.type === "value") {
        const additionalData = additionalColumn ? record?.values?.find(i => i.name === additionalColumn)?.value : null;

        return <div>
            {additionalData && <div>{additionalData}</div>}
        </div>;
    }

    return null;
}

const Wrapper = styled.div`
    margin-top: 20px;
`;

function findValue(event, key) {
    if (!event?.values?.find) return null;
    return event?.values?.find(i => i.name === key)?.value;
}

function valuesMap(event) {
    const vm = {};
    event?.values?.forEach(i => {
        vm[i.name] = i.value;
    });
    return vm;
}

function findColor(record, colors, colorColumn) {
    const value = record?.values?.find(i => i?.name == colorColumn);
    if (!value) return "blue";

    return colors[value?.value] ?? "blue";
}

function ownsResource(resourceId, resources, userId, userEdit) {
    if (userEdit?.includes(userId)) {
        return true;
    }

    const resource = resources?.find(i => i?.id == resourceId);
    if (!resource) return false;

    const resourceOwnerId = resource?.values?.find(i => i?.name == "user_id")?.value;
    if (!resourceOwnerId) return false;

    return parseInt(resourceOwnerId) == userId;
}

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}

const CustomTooltipRenderer = ({ eventRecord }) => {
    const tooltipContent = `
        <div>
            <strong>${eventRecord.name}</strong><br>
            Start: ${eventRecord.startDate}<br>
            End: ${eventRecord.endDate}
        </div>
    `;

    return null;
};

function transformDate(date) {
    if (!date) return date;

    const originalDate = new Date(Date.parse(date));

    const year = originalDate.getFullYear();
    const month = originalDate.getMonth();
    const day = originalDate.getDate();

    const newDate = new Date(Date.UTC(year, month, day));

    return format(newDate, "yyyy-MM-dd HH:ii");
}

const CustomEventComponent = ({ eventRecord }) => {
    return (
        <div style={{ backgroundColor: "red" }}>
            {eventRecord.name}
        </div>
    );
};

function generateTooltip(config, data, related) {
    console.log("GENERATE TOOLTIP", config?.tooltip, data, related);
    const replacements = data.eventRecord?.allValues ?? {};
    const optionsData = config?.tooltipOptions ?? {};

    return replaceKeys(config?.tooltip, replacements, related, optionsData);
}

function replaceKeys(template, data, related, optionsData) {
    // Regular expression to match keys inside curly braces with optional format
    const regex = /{(\w+)(?::(\w+)(?:,(.*?))?)?}/g;

    // Replace each occurrence of the keys with their corresponding values
    return template.replace(regex, (match, key, fn, formatStr) => {
        const value = data[key];
        if (value !== undefined && fns[fn]) {
            return fns[fn](value, formatStr, related);
        } else {
            const mapped = optionsData[key] ?? null;

            if (mapped) {
                return mapped[value] ?? value;
            }

            return value !== undefined ? value : "-";
        }
    });
}

// function replaceKeys(template, data, related) {
//     const regex = /{(\w+)(?::(\w+))?}/g;

//     return template.replace(regex, (match, key, formatKey) => {
//         const value = data[key];
//         if (value !== undefined) {
//             if (formatKey && fns[formatKey]) {
//                 return fns[formatKey](value, related);
//             } else {
//                 return value;
//             }
//         } else {
//             return match;
//         }
//     });
// }

const fns = {
    date: (d, f) => format(Date.parse(d), f ?? "yyyy-MM-dd"),
    currency: c => parseFloat(c?.toString()?.replace(",", ".")).toFixed(2)?.toString()?.replace(".", ","),
    related: (id, _, related) => {
        const d = related?.find(i => i?.id == id);
        if (!d) return "-";
        return d?.name;
    },
};

function compareDatesInDays(date1Str, date2Str) {
    try {
        // Parse the date strings into Date objects
        const date1 = new Date(date1Str);
        const date2 = new Date(date2Str);

        // Check if the dates are valid
        if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
            return 0;  // Return 0 if either date is invalid
        }

        // Reset the time portion to ignore time
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);

        // Calculate the difference in milliseconds
        const differenceInMillis = date1 - date2;

        // Convert the difference from milliseconds to days
        const differenceInDays = Math.round(differenceInMillis / (1000 * 60 * 60 * 24));

        return Math.abs(differenceInDays);  // Return the absolute difference in days
    } catch (error) {
        return 0;  // Return 0 if any error occurs
    }
}


function incrementDateByDays(dateStr, numberOfDays) {
    try {
        // Parse the date string into a Date object
        const date = new Date(dateStr);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return null;  // Return null if the date is invalid
        }

        // Add the specified number of days
        date.setDate(date.getDate() + numberOfDays);

        // Return the new Date object
        return date;
    } catch (error) {
        return null;  // Return null if any error occurs
    }
}

function convertToUTCDateOnly(dateStr) {
    try {
        // Parse the date string into a Date object
        const date = new Date(dateStr);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return null;  // Return null if the date is invalid
        }

        // Get the UTC components of the date
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
        const day = date.getUTCDate().toString().padStart(2, "0");

        // Construct the UTC date string in YYYY-MM-DD format
        const utcDateStr = `${year}-${month}-${day}`;

        return utcDateStr;
    } catch (error) {
        return null;  // Return null if any error occurs
    }
}


const FlexWrapper = styled.div`
    display: flex;
    padding: 10px;
    justify-items: space-between;
    margin-top: -64px;
    padding-right: 40px;
`;

const Spacer = styled.div`
    flex: 1;
`;