import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import AtlaskitInlineEdit from "@atlaskit/inline-edit";
import CellValue from "../../utils/cell-value";
import FormField from "./form-field";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@atlaskit/checkbox";
import Signature from "./signature";
import styled from "styled-components";
import EditIcon from "@atlaskit/icon/glyph/edit-filled";
import Documents from "../documents/documents";
import AbsoluteSpinner from "../ui/absolute-spinner";
import toBoolean from "../../utils/to-boolean";
import SectionMessage from "@atlaskit/section-message/section-message";
import SectionMessageAction from "@atlaskit/section-message/section-message-action";
import Widgets from "../widgets/widgets";
import { Divider, Heading } from "../documents/new-document-form";

export default function InlineEdit({ value: val, document, field, inputProps, editable = false, onSaved, fields, reload, root, setErrors, related = null, allUsers, usePortal = false, gridRef = undefined, noLinkThrough = false }) {
    const [value, setValue] = useState(val);
    const [editValue, setEditValue] = useState(() => {
        if (field?.field?.type == "files" && document?.id) {
            return document?.rawValues
                ?.filter(rv => rv?.document_type_field_id == field?.id)
                ?.map(i => i?.value)
                ?.map(i => {
                    try {
                        return JSON.parse(i);
                    } catch (err) {
                        return {};
                    }
                })
                ?? [];
        }



        if (field?.field?.type == "document-type-multi" || field?.field?.type == "multiselect" || field?.field?.type == "users" || field?.field?.type == "files") {
            return document?.rawValues?.filter(rv => rv?.document_type_field_id == field?.id)?.map(i => i?.value) ?? [];
        } else {
            return val;
        }
    });
    const [propagated, setPropagated] = useState([]);
    const [hover, setHover] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const isEditable = editable && !toBoolean(field?.read_only);

    useEffect(() => {
        if (field?.field?.type == "files" && document?.id) {
            setValue(document?.rawValues
                ?.filter(rv => rv?.document_type_field_id == field?.id)
                ?.map(i => i?.value)
                ?.map(i => {
                    try {
                        return JSON.parse(i);
                    } catch (err) {
                        return {};
                    }
                })
                ?? []);
        }

        if (field?.field?.type == "document-type-multi" || field?.field?.type == "multiselect" || field?.field?.type == "users" || field?.field?.type == "files") {
            setValue(document?.rawValues?.filter(rv => rv?.document_type_field_id == field?.id)?.map(i => i?.value) ?? []);
        } else {
            setValue(val);
        }
    }, [val]);

    useEffect(() => {
        if (field?.field?.type == "files" && document?.id) {
            setEditValue(document?.rawValues
                ?.filter(rv => rv?.document_type_field_id == field?.id)
                ?.map(i => i?.value)
                ?.map(i => {
                    try {
                        return JSON.parse(i);
                    } catch (err) {
                        return {};
                    }
                })
                ?? []);

            return;
        }

        setEditValue(() => {
            if (field?.field?.type == "document-type-multi" || field?.field?.type == "multiselect" || field?.field?.type == "users" || field?.field?.type == "files") {
                return document?.rawValues?.filter(rv => rv?.document_type_field_id == field?.id)?.map(i => i?.value) ?? [];
            } else {
                return val;
            }
        });
    }, [document?.hash]);
    const { t } = useTranslation();
    const save = (saveValue) => {
        setIsPending(true);
        setValue(saveValue);
        setEditValue(saveValue);
        setErrors && setErrors(null);

        if (field.field.type === "password") {
            setValue("");
            setEditValue("");
        }

        if ((field.field.type === "multiselect" || field.field.type == "document-type-multi" || field?.field?.type == "users" || field?.field?.type == "files") && !saveValue) {
            saveValue = [];
        }

        const rawOptions = field?.options?.[0];
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            console.error(e);
            //
        }

        if (optionsData?.format == "currency") {
            // saveValue = (new Intl.NumberFormat("pl-PL", { style: "currency", currency: optionsData?.currency ?? "PLN" })).format(parseFloat(saveValue?.replace(",", ".")) ?? 0);
            saveValue = parseFloat(saveValue?.toString()?.replace(",", ".")).toFixed(2)?.toString()?.replace(".", ",");

            // if (isNaN(saveValue)) {
            //     saveValue = "0,00";
            // }
        }

        return apiClient(`workflow/document/${document.id}`, {
            method: "PATCH",
            data: {
                [field.name]: saveValue
            }
        })
            .then(() => {
                if (!fields) return Promise.resolve();

                return Promise.all(propagated?.map(prop => {
                    const propagatingField = prop.field;
                    const valuesMap = prop.propagated?.values?.reduce((acc, i) => {
                        acc[i?.name] = i?.value;
                        return acc;
                    }, {});


                    const propagatingFieldOptions = parseOptions(propagatingField?.options);
                    const documentTypeId = propagatingFieldOptions?.documentTypeId;
                    const toEdit = {};

                    fields.map(f => {
                        const fieldOptions = parseOptions(f?.options);

                        if (fieldOptions?.document_type?.id == documentTypeId && fieldOptions?.document_type?.name === propagatingField?.name) {
                            const fieldName = fieldOptions?.document_type?.field_name;
                            const value = valuesMap[fieldName];

                            toEdit[f?.name] = value;
                        }

                    });

                    if (Object.keys(toEdit).length === 0) return Promise.resolve();

                    return apiClient(`workflow/document/${document.id}`, {
                        method: "PATCH",
                        data: toEdit,
                    }).catch(() => { });
                }));
            })
            .then(() => {
                setPropagated([]);
            })
            .then(() => {
                setHover(false);
                onSaved && onSaved(saveValue);
            })
            .catch((e) => {
                setErrors && setErrors(e?.errors);
                toast.error(t("inline_edit_error"));
            })
            .finally(() => setIsPending(false));
    };

    if (field.field.type === "checkbox") {
        return <Checkbox
            defaultChecked={inputProps.defaultValue}
            defaultValue={true}
            value={value}
            onChange={e => {
                save(e?.target?.checked);
            }}
            label={field?.label ?? field?.options?.label} />;
    }

    if (field.field.type === "widgets") {
        const rawOptions = field?.options?.[0];
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            console.error(e);
            //
        }
        return <Widgets widgets={optionsData?.widgets} documentId={document?.id} />;
    }

    if (field.field.type === "signature") {
        return <Signature field={field} value={editValue} onChange={save} editable={isEditable} />;
    }

    if (field.field.type == "divider") {
        return <Divider />;
    }

    if (field.field.type == "heading") {
        const rawOptions = field?.options?.[0];
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            console.error(e);
            //
        }

        return <Heading size={optionsData?.size} label={field.label} />;
    }

    if (field.field.type === "message") {
        const rawOptions = field?.options?.[0];
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            console.error(e);
            //
        }

        let messageData = null;
        try {
            messageData = JSON.parse(value);
            // eslint-disable-next-line no-empty
        } catch (e) { }

        if (messageData) {
            return <SectionMessageWrapper>
                <SectionMessage
                    title={messageData?.title ?? optionsData?.title}
                    appearance={messageData?.appearance ?? optionsData?.appearance}
                    actions={messageData?.actions?.map(a => (
                        <SectionMessageAction key={a.label} href={a?.href}>{a?.label}</SectionMessageAction>
                    ))}

                >
                    {messageData?.body ?? optionsData?.body}
                </SectionMessage>
            </SectionMessageWrapper>;
        }

        if (!optionsData?.body) {
            return <UserSectionMessage
                title={optionsData?.title}
                appearance={optionsData?.appearance}
                value={value}
            />;
        }

        return <SectionMessageWrapper>
            <SectionMessage
                title={optionsData?.title}
                appearance={optionsData?.appearance}
                actions={optionsData?.actions?.map(a => (
                    <SectionMessageAction key={a.label} href={a?.href}>{a?.label}</SectionMessageAction>
                ))}
            >
                {optionsData?.body}
            </SectionMessage>
        </SectionMessageWrapper>;
    }

    if (field.field.type === "list") {
        const rawOptions = field?.options?.[0];
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            console.error(e);
            //
        }
        return <Documents
            document={document}
            linkTypeId={optionsData?.link_type_id}
            documentTypeId={optionsData?.document_type_id}
            document_links={optionsData?.links}
            editMode={isEditable}
            colorField={optionsData?.colorField}
            colorColumn={optionsData?.colorColumn}
            bgField={optionsData?.bgField}
            columnsWidths={optionsData?.columnsWidths ?? {}}
            defaultColumnWidth={optionsData?.defaultColumnWidth}
            createButton
            slug={optionsData?.space}
            columns={optionsData?.columns} />;
    }

    if (!isEditable) {
        return <CellValue
            allUsers={allUsers} value={value} document={document} field={field} editable={isEditable} related={related}
        />;
    }

    if (field?.field?.type === "files") {
        return <>
            {isEditable && <FormField
                autoFocus
                noLabel
                root={root}
                field={field}
                value={editValue}
                contextDocument={root?.links?.filter(l => l?.link_type_id === 5)?.[0]?.linked_document}
                propagateFields={(propagatingField, valuesMap) => {
                }}
                document={document}
                type={field.field.type == "password" ? "password" : "text"}
                onSaved={save}
                onChange={(field, val, propagated) => {
                    setEditValue(val);
                    setPropagated(p => {
                        const filtered = p?.filter(i => i?.name != field?.name);
                        filtered?.push({
                            name: field?.name,
                            field,
                            propagated,
                        });
                        return filtered;
                    });

                    save(val);
                }}
                inputProps={inputProps}
            />}

            {!isEditable && <CellValue allUsers={allUsers} value={value} document={document} field={field} editable={isEditable} related={related} />}
        </>;
    }

    return <InlineWrapper>
        <AtlaskitInlineEdit
            defaultValue={editValue}
            isRequired={inputProps.isRequired}
            editView={({ errorMessage, ...fieldProps }) => {
                const ref = React.useRef();
                const [position, setPosition] = React.useState({ top: 0, left: 0, width: 0 });

                // Update position whenever the window is resized or scrolled
                React.useEffect(() => {
                    const updatePosition = () => {
                        if (ref.current) {
                            const rect = ref.current.getBoundingClientRect();
                            setPosition({
                                top: rect.top + window.scrollY, // Adjust for scroll position
                                left: rect.left + window.scrollX, // Adjust for scroll position
                                width: rect.width,
                            });
                        }
                    };

                    // Run initially to get the position
                    updatePosition();

                    // Add event listeners for resize, scroll, and grid scroll
                    const gridElement = gridRef?.current?.grid?.current; // Access the grid's DOM element
                    gridElement?.addEventListener('scroll', updatePosition); // Listen for grid scroll

                    window.addEventListener('resize', updatePosition);
                    window.addEventListener('scroll', updatePosition);

                    // Cleanup listeners on unmount
                    return () => {
                        window.removeEventListener('resize', updatePosition);
                        window.removeEventListener('scroll', updatePosition);
                        gridElement?.removeEventListener('scroll', updatePosition);
                    };
                }, [gridRef]);



                if (!usePortal) {
                    return <FormField {...fieldProps}
                        autoFocus
                        noLabel
                        root={root}
                        field={field}
                        value={editValue}
                        contextDocument={root?.links?.filter(l => l?.link_type_id === 5)?.[0]?.linked_document}
                        propagateFields={(propagatingField, valuesMap) => {
                        }}
                        document={document}
                        type={field.field.type == "password" ? "password" : "text"}
                        onSaved={save}
                        onChange={(field, val, propagated) => {
                            setEditValue(val);
                            setPropagated(p => {
                                const filtered = p?.filter(i => i?.name != field?.name);
                                filtered?.push({
                                    name: field?.name,
                                    field,
                                    propagated,
                                });
                                return filtered;
                            });
                        }}
                        inputProps={inputProps}
                    />;

                }

                return (
                    <>
                        <div ref={ref}>
                            <EditWrapper>
                                <EditContent>

                                </EditContent>
                            </EditWrapper>
                        </div>
                        {
                            ReactDOM.createPortal(
                                <div
                                    style={{
                                        position: "absolute",
                                        top: (position.top - 12) + 'px', // Use updated position from state
                                        left: position.left + 'px', // Use updated position from state
                                        width: position.width + "px",
                                        zIndex: 999,
                                    }}
                                >
                                    <FormField {...fieldProps}
                                        autoFocus
                                        noLabel
                                        root={root}
                                        field={field}
                                        value={editValue}
                                        contextDocument={root?.links?.filter(l => l?.link_type_id === 5)?.[0]?.linked_document}
                                        propagateFields={(propagatingField, valuesMap) => {
                                        }}
                                        document={document}
                                        type={field.field.type == "password" ? "password" : "text"}
                                        onSaved={save}
                                        onChange={(field, val, propagated) => {
                                            setEditValue(val);
                                            setPropagated(p => {
                                                const filtered = p?.filter(i => i?.name != field?.name);
                                                filtered?.push({
                                                    name: field?.name,
                                                    field,
                                                    propagated,
                                                });
                                                return filtered;
                                            });
                                        }}
                                        inputProps={inputProps}
                                    />
                                </div>,
                                window.document.body // Render the portal outside the grid
                            )
                        }
                    </>
                );
            }}
            readView={() => (
                <Wrapper onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <FlexMe>
                        <CellValue noLinkThrough={noLinkThrough} allUsers={allUsers} value={value} document={document} field={field} editable={isEditable} related={related} />
                    </FlexMe>
                    {hover && <EditIcon size="small" />}
                </Wrapper>
            )}
            onConfirm={(v, e) => {
                setValue(v);
                save(v);
            }}
            onCancel={() => {
                setEditValue(value);
                setHover(false);
            }}
        />
        {isPending && <AbsoluteSpinner />}
    </InlineWrapper >;
}

const InlineWrapper = styled.div`
margin-top: -8px;
`;

const EditWrapper = styled.div`
z-index: 999 !important;
position: relative;
width: 100%;
overflow: visible;
`;
const EditContent = styled.div`
z-index: 999 !important;
position: absolute;
top: -8px;
left: 0;
min-width: 100%;
`;

export const Wrapper = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-right: 10px;
    padding: 5px 10px;
`;

export const FlexMe = styled.div`
    flex: 1
    margin-right: 10px;
`;

const SectionMessageWrapper = styled.div`
margin-top: 10px;
font-size: 14px;
line-height: 1.3rem;
`;

function UserSectionMessage({ title, appearance, value }) {
    if (!value || value?.length === 0) return null;

    return <SectionMessageWrapper>
        <SectionMessage
            title={title}
            appearance={appearance}
        >
            {value}
        </SectionMessage>
    </SectionMessageWrapper>;
}

function parseOptions(opt) {
    const optString = opt?.[0] || "{}";

    try {
        return JSON.parse(optString);
    } catch (e) {
        return {};
    }
}